import resolveImage from "./resolveImage";

const resolveResource = r => {
  const { metaImage, audiences, topics, metaDescription } = r;

  return {
    ...r,
    audiences: audiences?.map(a => a.uid),
    topics: topics?.map(t => t.uid),
    description: metaDescription,
    image: resolveImage(metaImage),
    type: r.type || "article",
  };
};

export default resolveResource;
