import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";

const Cta = loadable(() => import("@organisms/Cta"));
const Hero = loadable(() => import("@organisms/Hero"));
const NewsletterCta = loadable(() => import("@organisms/NewsletterCta"));
const ResourceList = loadable(() => import("@organisms/ResourceList"));

const ResourcesIndexPage = ({ data, pageContext }) => {
  const { hero, resources, meta } = data;

  const [, dispatch] = useAppState();
  useEffect(() => {
    dispatch({ type: "setThemeDark" });
  }, []);

  return (
    <PageContainer meta={meta}>
      <Hero {...hero} />
      <ResourceList items={resources} className="flex-1" />
      <Cta />
      <NewsletterCta />
    </PageContainer>
  );
};

ResourcesIndexPage.defaultProps = {};

export default ResourcesIndexPage;
