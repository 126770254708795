/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveResource from "@dataResolvers/resolveResource";
import PageTemplate from "@pageTemplates/ResourcesIndexPage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ResourcesIndexQuery {
    craft {
      entries(section: "resources") {
        ... on Craft_resources_article_Entry {
          uid
          title
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_resources_default_Entry {
          uid
          title
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_resources_firesideChat_Entry {
          uid
          title
          uid
          url
          type: typeHandle
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_resources_webinar_Entry {
          uid
          title
          uid
          url
          type: typeHandle
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
        ... on Craft_resources_whitepaper_Entry {
          uid
          title
          uid
          url
          type: typeHandle
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entry(section: "resourcesIndex") {
        ... on Craft_resourcesIndex_resourcesIndex_Entry {
          heroCopy: copy0
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Meta
          slug
          url
          title
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    heroCopy,
    heroBackgroundImage,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  const resources = entries.map(resolveResource);

  return {
    hero: {
      copy: heroCopy,
      image: resolveImage(heroBackgroundImage),
    },
    resources,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ResourcesIndexResourcesIndexQuery"
    {...props}
  />
);

export default Page;
